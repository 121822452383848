import * as React from "react";
import Layout from "../components/layout";
import Carousel from "../components/home/carousel";
import SchoolYears from "../components/home/schoolYears";
import BookATour from "../components/home/bookATour";
import WhatsGoingOn from "../components/home/whatsGoingOn";
import NewsEvents from "../components/home/newsEvents";
import FollowUsBanner from "../components/home/followUsBanner";
import SchoolEntryVideo from "../components/home/video";
import CovidBar from "../components/home/CovidBar";

import {graphql} from "gatsby";
import {
    filterOutDocsPublishedInTheFuture,
    filterOutDocsWithoutSlugs,
    mapEdgesToNodes,
} from "../lib/helpers";
import BlogPostPreviewList from "../components/sanity/blog-post-preview-list";
import Container from "../components/sanity/container";
import GraphQLErrorList from "../components/sanity/graphql-error-list";
import OpenDayBar from "../components/home/OpenDay";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    posts: allSanityNewspage(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
    const {data, errors} = props;

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors}/>
            </Layout>
        );
    }

    const postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : [];

    return (
        <Layout>
            <Carousel/>
            <OpenDayBar />
            {/*<CovidBar />*/}
            <SchoolEntryVideo/>
            <SchoolYears/>
            <BookATour/>
            {/* <WhatsGoingOn /> */}
            <NewsEvents/>
            <Container>
                {postNodes && (
                    <BlogPostPreviewList
                        // title="Latest Blog Posts"
                        nodes={postNodes}
                        // browseMoreHref="/archive/"
                    />
                )}
            </Container>
            {/* <FollowUsBanner /> */}
        </Layout>
    );
};

export default IndexPage;
